import Vue from 'vue'
import Component from 'vue-class-component'
import he, { EncodeOptions, DecodeOptions } from 'he';

@Component
export class HeMixin extends Vue {
  htmlEncode(text: string, options?: EncodeOptions) {
    return encodeURIComponent(he.encode(text, options));
  }

  htmlDecode(html: string, options?: DecodeOptions) {
    return he.decode(decodeURIComponent(html), options);
  }
}