import Vue from 'vue'
import Component from 'vue-class-component'

// for now default to cad
const localFormatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD'
});

@Component
export class CurrencyFormatMixin extends Vue {
  formatLocaleCurrency(val: number, _default = ''): string {
    const c = +val;
    if (isNaN(c)) {
      return _default;
    }
    return localFormatter.format(val);
  }
}