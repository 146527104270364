import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment-timezone';

// This is a hack to hard code the date time from the server which does not have
// contain the timezone. See createDate method in this mixin. When server starts specifying the dates
// in ISO format (including timezone) then we can remove this
const TIMEZONE = 'America/Denver';

const p0 = (i: number) => (i >= 10 ? `${i}` : `0${i}`)

@Component
export class DateFormatMixin extends Vue {

  formatNow(format: string) {
    return moment().format(format);
  }

  formatHours(n: number) {
    const hours = Number(n);
    if (isNaN(hours)) {
      return '00:00';
    }
    const duration = moment.duration(hours, 'hours');
    // return `${duration.asHours()}:00`
    const d = duration.days(), h = duration.hours(), m = duration.minutes();
    const f = `${p0(h)}:${p0(m)}`;
    return d ? `${d} day${d > 1 ? 's' : ''} ${f}`.trim() : f;
    // console.log(moment.utc(Number(hours) * 3600 * 1000).format('HH:mm'));
    // return moment.utc(Number(hours) * 3600 * 1000).format('HH:mm')
  }

  formatDate(format: string, date?: string | null): string {
    if (!date) {
      return '';
    }

    const d = this.createDate(date);
    return d.format(format);
  }

  formatCheckYear(date: string, formatDiffYear = 'MMM D YYYY hh:mma', formatSameYear = 'MMM D hh:mma') {
    const d = this.createDate(date);
    const y = moment().year();
    if (y === d.year()) {
      return d.format(formatSameYear);
    }

    return d.format(formatDiffYear);
  }

  fromNowLocal(date?: string | null, suffix = false) {
    return this.fromNow(date, suffix, true);
  }

  fromNow(date?: string | null, suffix = false, toLocal = false) {
    if (!date) {
      return '';
    }

    const d = this.createDate(date);
    return toLocal ? d.local().fromNow(suffix) : d.fromNow(suffix);
  }

  fromNowUnix(unix: number, suffix = false, isMilliseconds = false) {
    if (!isMilliseconds) {
      unix = unix * 1000;
    }

    const d = moment(unix);

    return d.fromNow(suffix);
  }

  createDate(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, strict?: boolean, tz?: string): moment.Moment {
    // console.log('Original:', inp, moment(inp, format, strict).format(), moment(inp, format, strict).tz(tz || TIMEZONE).format());
    return moment(inp, format, strict).tz(tz || TIMEZONE);
  }

  formatTimeRange(start?: string | null, end?: string | null) {
    if (!start || !end) {
      return "Invalid date range";
    }

    let s = moment(start),
      e = moment(end);

    if (s.isAfter(e)) {
      let tmp: moment.Moment | undefined = e;
      e = s;
      s = tmp;
      tmp = undefined;
    }

    const y = new Date().getFullYear();

    if (s.isSame(e, "day")) {
      const f = s.year() === y ? "MMM D" : "MMM D YYYY";
      return `${s.format(f)} ${s.format("hh:mm a")} - ${e.format("hh:mm a")}`;
    }

    const sf = s.year() === y ? "MMM D hh:mm a" : "MMM D YYYY hh:mm a";
    const ef = e.year() === y ? "MMM D hh:mm a" : "MMM D YYYY hh:mm a";
    return `${s.format(sf)} - ${e.format(ef)}`;
  }

  diffAsHours(start?: string | null, end?: string | null, defaultOnError = 0): number {
    const duration = this.calcDuration(start, end);

    if (typeof duration === 'string') {
      return defaultOnError;
    }

    return duration.asHours();
  }

  calcDuration(start?: string | null, end?: string | null) {
    if (!start || !end) {
      return "Invalid date range";
    }

    let s = moment(start),
      e = moment(end);

    if (s.isAfter(e)) {
      let tmp: moment.Moment | undefined = e;
      e = s;
      s = tmp;
      tmp = undefined;
    }

    return moment.duration(e.diff(s));
  }
}